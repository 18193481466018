import React, { useEffect, useState, useCallback } from "react";
import "animate.css";

import { polyfill } from "smoothscroll-polyfill";
import { useHandleScroll } from "@/hooks";
import { useTranslation } from "react-i18next";

import dynamic from "next/dynamic";

const MainLayout = dynamic(() => import("@/layouts/MainLayout"), {
  ssr: false,
});

const NewMain = dynamic(() => import("@/components/home/newMain/NewMain"), {
  ssr: false,
});
const ComprehensiveTools = dynamic(
  () => import("@/components/home/comprehensiveTools/ComprehensiveTools"),
  { ssr: false }
);
const Innovate = dynamic(() => import("@/components/home/innovate/Innovate"), {
  ssr: false,
});
const VersatileSoftware = dynamic(
  () => import("@/components/home/versatileSoftware/VersatileSoftware"),
  { ssr: false }
);
const Quotes = dynamic(() => import("@/components/home/quotes/Quotes"), {
  ssr: false,
});
const YourQuestions = dynamic(
  () => import("@/components/home/yourQuestions/YourQuestionst"),
  { ssr: false }
);
const SmartSoftware = dynamic(
  () => import("@/components/home/smartSoftware/SmartSoftware"),
  { ssr: false }
);

const AutoSlider = dynamic(
  () =>
    import("@/components/home/functioning/functioningComponents/AutoSlider"),
  { ssr: false }
);
const ContactUs = dynamic(
  () => import("@/components/home/contactUs/ContactUs"),
  {
    ssr: false,
  }
);

const Solutions = dynamic(
  () => import("@/components/home/solutions/Solutions"),
  {
    ssr: false,
  }
);

export default function Home() {
  const { t } = useTranslation("global");
  const { selecteditem, handleScroll } = useHandleScroll();
  const [value, setValue] = useState(0);

  useEffect(() => {
    polyfill();
  }, []);

  const listenScroll = useCallback(
    (event: any) => {
      requestAnimationFrame(() => {
        handleScroll(event);
      });
    },
    [handleScroll]
  );

  useEffect(() => {
    window.addEventListener("scroll", listenScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", listenScroll);
    };
  }, [listenScroll]);

  return (
    <MainLayout
      title="Cellect Energy"
      description="Our platform offers a universal solution for energy storage asset managers, providing a standard API for remote control which eliminates integration needs."
      navbarTheme="dark"
    >
      <NewMain />
      <ComprehensiveTools />
      <Innovate />
      <VersatileSoftware setValue={setValue} />
      <AutoSlider />
      <Quotes />

      <YourQuestions
        title={t("bannerTwo.title")}
        description={t("bannerTwo.text")}
        button={t("bannerTwo.bookACall")}
      />
      <SmartSoftware />
      <Solutions value={value} setValue={setValue} />
      <ContactUs />
      <YourQuestions
        title={t("bannerOne.title")}
        description={t("bannerOne.text")}
        button={t("bannerOne.bookACall")}
      />
    </MainLayout>
  );
}
